
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import Submissions from "./submissions.vue";
import { useStore } from "vuex";
import { AxiosRequestConfig } from "axios";
import { event } from "vue-gtag";

interface Research {
  _id: string;
  trimester: string;
  createdAt: string;
  updatedAt: string;
  subject: string;
  title: string;
  module: string;
  desc: string;
  deadline: string;
  teachers: string[];
  levels: string[];
  files: string[];
  photos: string[];
}

interface Teacher {
  _id: string;
  firstName: string;
  lastName: string;
  employee: {
    firstName: string;
    lastName: string;
  };
}

interface Comment {
  _id: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  photo: string;
  text: string;
  user: { _id: string; role: string };
}

export default defineComponent({
  name: "research-details",
  components: {
    Submissions,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const user = store.getters.currentUser;
    const fullName = user.employee.firstName + " " + user.employee.lastName;

    const comments = ref<Comment[]>([]);

    const researchID = route.params.id;

    const research = ref<Research>({
      subject: "",
      _id: "",
      title: "",
      module: "",
      desc: "",
      createdAt: "",
      updatedAt: "",
      deadline: "",
      files: [],
      photos: [],
      trimester: "",
      teachers: [],
      levels: [],
    });

    const teacherList = ref<Teacher[]>([]);

    const getTeachers = (teachers: []) => {
      ApiService.post("/schedules/filter", {
        query: {
          status: { $ne: "inactive" },
          hidden: { $ne: true },
        },
        aggregation: [
          {
            $lookup: {
              from: "teachers",
              localField: "teacher",
              foreignField: "_id",
              as: "teacher",
            },
          },
          {
            $lookup: {
              from: "teachers",
              localField: "teacher.employee",
              foreignField: "_id",
              as: "teacherName",
            },
          },
          {
            $project: {
              _id: 1,
              teacherName: {
                firstName: 1,
                lastName: 1,
              },
              teacher: {
                $let: {
                  vars: {
                    teacherVar: {
                      $arrayElemAt: ["$teacher", 0],
                    },
                  },
                  in: {
                    _id: "$$teacherVar._id",
                    employee: "$$teacherVar.employee",
                  },
                },
              },
            },
          },
        ],
      })
        .then(({ data }) => {
          data = data.filter(
            (d) =>
              (!d.subject || d.subject.status !== "inactive") &&
              (!d.module || d.module.status !== "inactive")
          );
          data.forEach((data) => {
            const teacher = teacherList.value.find(
              (t) => t._id == data.teacher._id
            );
            if (!teacher && teachers.find((t) => t == data.teacher._id))
              teacherList.value.push(data.teacher);
          });
        })
        .catch((e) => console.log(e));
    };

    ApiService.get(`/lms/advisor/research/${researchID}`)
      .then(({ data }) => {
        research.value = {
          subject: data.subject.name,
          _id: data._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          deadline: data.deadline,
          files: data.resources.filter(
            (f) => !["png", "jpg"].includes(f.split(".")[1])
          ),
          photos: data.resources
            .filter((f) => ["png", "jpg"].includes(f.split(".")[1]))
            .map(
              (p: string) => store.getters.serverConfigUrl.base_url + "/" + p
            ),
          trimester: data.trimester ? data.trimester : "0",
          teachers: data.teachers,
          levels: data.levels,
        };
        if (data.teachers && data.teachers.length != 0)
          getTeachers(data.teachers);
      })
      .catch((e) => {
        console.log(e);
      });

    ApiService.get(`/lms/comment/research/${researchID}`)
      .then(({ data }) => {
        data.forEach((comment: Comment) => {
          comment.photo = comment.photo
            ? store.getters.serverConfigUrl.base_url +
              "/" +
              comment.photo.replace(/\\/, "/")
            : "media/avatars/blank.png";
          comments.value.push(comment);
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const confirmDelete = () => {
      Swal.fire({
        title: t("course.confirmDeleteResearch"),
        text: t("course.deleteResearchWarn"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("course.acceptDelete"),
      }).then((result) => {
        if (result.isConfirmed) {
          event("Delete research", {
            event_category: "Research",
            event_label: "Research section",
            value: 1,
          });
          ApiService.delete("/lms/advisor/research/" + researchID)
            .then(() => {
              Swal.fire(
                t("course.deleted"),
                t("course.deletedResearchInfo"),
                "success"
              ).then(() => {
                router.push(`/research`);
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        [
          "pdf",
          "png",
          "jpg",
          "ppt",
          "xls",
          "txt",
          "gif",
          "doc",
          "pptx",
          "xlsx",
          "docx",
          "zip",
          "mkv",
          "avi",
          "mp4",
          "mp3",
        ].includes(type)
      )
        return type;
      return "file";
    };

    const getFileLink = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      if (fileName.length > 20)
        return (
          "..." + fileName.substring(fileName.length - 20, fileName.length)
        );
      return fileName;
    };

    const commentText = ref<string>("");

    const sendComment = (): void => {
      ApiService.put("/lms/comment/", {
        research: researchID,
        teacher: user._id,
        text: commentText.value,
      } as AxiosRequestConfig)
        .then(({ data }) => {
          event("Add comment", {
            event_category: "Research",
            event_label: "Research section",
            value: 1,
          });
          data.fullName =
            user.employee.firstName + " " + user.employee.lastName;
          data.photo = user.employee.photo
            ? store.getters.serverConfigUrl.base_url +
              "/" +
              user.employee.photo.replace(/\\/, "/")
            : "media/avatars/blank.png";
          data.user = {
            role: "teacher",
            _id: user._id,
          };
          comments.value.push(data);
          commentText.value = "";
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const deleteComment = (id: string): void => {
      Swal.fire({
        title: t("comment.confirmDelete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("course.acceptDelete"),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(`/lms/comment/${id}`)
            .then(() => {
              event("Delete comment", {
                event_category: "Research",
                event_label: "Research section",
                value: 1,
              });

              comments.value = comments.value.filter(
                (comment) => comment._id != id
              );
              Swal.fire({
                text: t("comment.deleted"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("course.okay"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .catch((e) => {
              console.log(e);
              Swal.fire({
                text: t("course.errorText"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("course.tryAgain"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    return {
      t,
      researchID,
      research,
      confirmDelete,
      moment,
      getFileIconName,
      getFileLink,
      getShortFileName,
      comments,
      fullName,
      sendComment,
      deleteComment,
      commentText,
      teacherList,
    };
  },
});
